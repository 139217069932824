























































































































































































































































































































































































































// @ is an alias to /src
import { MutationPayload } from 'vuex';
import store from '@/store';
import { getEventBrandAnswers } from '@/api/brands';
import { addExhibitorContactRequest, getExhibitorById } from '@/api/exhibitors';
import { IBrandField, IParticipatingAnswer } from '@/models/brand';
import { getParticipatingFields } from '@/api/fields';
import { getFileById } from '@/api/files';
import { IProductItem } from '@/models/product';
import isJson from '@/utils/isJson';
import getYoutubeId from '@/utils/getYoutubeId';
import { IExhibitor } from '@/models/exhibitor';
import { createRocketInstantMessage, getRocketUser } from '@/api/chat';
import { ISession } from '@/models/session';
import { IFavorite, IFavoriteParams } from '@/models/visitor';
import { getProductList } from '@/api/products';
import ProductCard from '@/partials/ProductCard.vue';
import SessionCard from '@/partials/SessionCard.vue';
import ExhibitorDetails from '@/partials/ExhibitorDetails.vue';
import Loading from '@/components/loading.vue';
import { getVotingPoles } from '@/api/votes';
import { addFavorite, deleteFavorite, getFavorites } from '@/api/visitors';
import { computed, defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';

export default defineComponent({
  components: {
    ProductCard,
    SessionCard,
    ExhibitorDetails,
    Loading,
  },
  data() {
    return {
      exhibitor: {} as IExhibitor,
      participatingFields: [] as IBrandField[],
      exhibitorAnswers: [] as IParticipatingAnswer[],
      loading: false,
      loadingProducts: false,
      exhibitorId: 0,
      event: 0,
      logoUrl: '',
      profileUrl: '',
      videoUrl: '',
      meetingRoom: false,
      products: [] as IProductItem[],
      currentPage: 1,
      pageCount: 0,
      vote: false,
      favorite: {} as IFavorite,
      favoritesCount: 0,
    };
  },
  computed: {
    exhibitorInitial(): string {
      return this.exhibitor.name && this.exhibitor.name.charAt(0);
    },
  },
  setup() {
    const sessions = computed(() => store.getters.getSessions);
    const exhibitors = computed(() => store.getters.getExhibitors);
    const sessionsState = computed(() => store.getters.getSessionsState);
    const { translations, contextVisitor, contextExhibitor, language, contextEvent } = useContext();

    return {
      translations,
      contextVisitor,
      contextExhibitor,
      language,
      contextEvent,
      sessions,
      exhibitors,
      sessionsState,
    };
  },

  created() {
    this.initExhibitor();
  },

  methods: {
    initExhibitor() {
      this.exhibitorId = parseInt(this.$route.params.exhibitor, 10);
      this.event = parseInt(this.$route.params.event, 10);
      this.getProducts();
      this.getExhibitorInformation();
      getVotingPoles({
        event: this.event,
      }).then((response) => {
        this.vote = response.data.results.length > 0;
      });

      this.getFavorites();

      store.subscribe((mutation: MutationPayload) => {
        if (
          (mutation.type === 'setContextVisitor' && mutation.payload.id) ||
          (mutation.type === 'setContextExhibitor' && mutation.payload.id)
        ) {
          this.getFavorites();
        }
        if (mutation.type === 'setLanguage') {
          this.getExhibitorInformation();
          this.getProducts();
        }
      });
      this.getExhibitorSessions();
    },
    async addFavorite() {
      if (this.contextVisitor.id) {
        const response = await addFavorite({
          source_type: 'ex',
          source_id: this.exhibitorId,
          visitor: this.contextVisitor.id,
        });
        this.favorite = response.data;
      } else {
        store.commit('openLoginPrompt');
      }
    },

    async deleteFavorite() {
      await deleteFavorite(this.favorite.id);
      this.favorite = {} as IFavorite;
    },

    async getFavorites() {
      if (this.contextVisitor.id) {
        const response = await getFavorites({
          source_type: 'ex',
          visitor: this.contextVisitor.id,
          source_id: this.exhibitorId,
        });
        const favorites = response.data.results;
        if (favorites[0]) {
          [this.favorite] = favorites;
        }
      }
      if (this.contextExhibitor.id) {
        const favoritesParams: IFavoriteParams = {
          source_type: 'ex',
          source_id: this.exhibitorId,
        };
        getFavorites(favoritesParams).then((response) => {
          this.favoritesCount = response.data.item_count;
        });
      }
    },

    getProducts(nextPage = false) {
      if (nextPage) {
        this.currentPage += 1;
      }
      this.loadingProducts = true;
      getProductList({
        event: this.event,
        page: this.currentPage,
        page_size: 2,
        exhibitor: this.exhibitorId,
        submitted: true,
      }).then((response) => {
        this.pageCount = response.data.page_count;
        if (nextPage) {
          this.products = [...this.products, ...response.data.results];
        } else {
          this.products = response.data.results;
        }
        this.loadingProducts = false;
      });
    },

    filterSessions(session: ISession, sessionDay: Date) {
      const sessionDate = new Date(session.start_date);
      return sessionDay && sessionDate && sessionDate.getDate() === sessionDay.getDate();
    },

    getDate(date: string) {
      const newDateString = date.split('T')[0];
      const newDate = new Date(newDateString);
      return newDate;
    },

    getTime(date: string) {
      const newDate = new Date(date);
      return newDate.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    },

    getExhibitorSessions() {
      const today = new Date();
      // const todayString = today.toISOString();
      const todayString = `${today.toISOString().split('.')[0]}Z`;

      store.dispatch('fetchSessions', {
        event: this.event,
        end_date_gte: todayString,
        exhibitor: this.exhibitorId,
        type: 'exhibitor',
        private: false,
        page: 1,
        page_size: 100,
      });
    },

    getExhibitorInformation() {
      this.loading = true;

      getParticipatingFields(this.event).then((response) => {
        this.participatingFields = response.data.results;
        this.meetingRoom = this.participatingFields.some(
          (field) => field.title === 'BridgeMeetingRoom',
        );
        getEventBrandAnswers(this.exhibitorId).then((response) => {
          const allExhibitorAnswers = response.data.results;
          this.exhibitorAnswers = [];
          response.data.results.forEach(async (answer) => {
            const field = this.participatingFields.find(
              (field) => answer && answer.participating_field === field.id,
            );

            // Check dependencies - whether the answer should
            // be displayed or not, based on other answers
            if (
              field &&
              answer.answer &&
              this.fieldIsPassingDependencyValidation(field, allExhibitorAnswers)
            ) {
              if (field.title === 'BridgeMeetingRoom') {
                this.meetingRoom = ['yes', 'sim', 'sí', 'si'].includes(
                  (<string>answer.answer).toLowerCase(),
                );
              }
              // Text, Textarea, Select, Radio button todo: covered - take nonformatted answer

              // Files (Validation Image, Square Image)
              if (['image', 'square_image'].includes(field.validation) && answer.answer !== '') {
                const fileResponse = await getFileById(<string>answer.answer);
                const { file } = fileResponse.data;
                answer.answer = file;
              }

              // Checkbox
              if (answer.answer && field.field_type === 'checkbox') {
                this.extractSelectedCheckboxes(answer);
              }
              // Profile picture (Custom fields)
              if (field.title === 'BridgeProfilePic') {
                this.profileUrl = <string>answer.answer;
              }

              // Logo (Custom fields)
              if (field.title === 'BridgeLogo') {
                this.logoUrl = <string>answer.answer;
              }

              // Youtube embedded link (Custom fields)
              if (field.title === 'BridgeEmbeddedVideo') {
                this.videoUrl = this.getYoutubeEmbededLink(<string>answer.answer);
              }

              if (field.title && field.title.includes('Bridge')) {
                return;
              }

              const exhibitorAnswer = {
                ...answer,
                title: field.title,
                field_type: field.field_type,
                validation: field.validation,
                order: field.order,
                hide_from_visitors: field.hide_from_visitors, // temporary
                answer: isJson(answer.answer) ? JSON.parse(<string>answer.answer) : answer.answer,
              };

              this.exhibitorAnswers.push(exhibitorAnswer);
            }
          });
          this.exhibitorAnswers = this.exhibitorAnswers.sort((prev, next) => {
            if (next.order && prev.order && prev.order < next.order) {
              return -1;
            }
            return 1;
          });
          this.loading = false;
        });
      });
      getExhibitorById(this.exhibitorId).then((response) => {
        this.exhibitor = response.data;
        setTimeout(() => {
          this.$gtag.event(`exhibitor_viewed_${this.exhibitor.id}`, {
            event_category: `event_${this.event}`,
            event_label: `visitor_${this.contextVisitor.id}`,
          });
        }, 1000);
      });
    },

    extractSelectedCheckboxes(answer: any) {
      try {
        const options = JSON.parse(answer.answer);
        const checkedOptions = options.filter((option: any) => option.checked);
        answer.answer = JSON.stringify(checkedOptions);
      } catch (error) {
        // Could not extract values
        answer.answer = '';
      }
    },

    fieldIsPassingDependencyValidation(
      dependentField: IBrandField,
      allExhibitorAnswers: IParticipatingAnswer[],
    ) {
      // Field that is dependent
      if (dependentField.dependence_field) {
        // Answer on dependency field
        const dependencyFieldAnswer = allExhibitorAnswers.find(
          (exhibitorAnswer) =>
            exhibitorAnswer.participating_field === dependentField.dependence_field,
        );

        // Check type of dependency and validate the value

        // operator "=="
        if (dependencyFieldAnswer && dependentField.dependence_operator === '==') {
          if (dependentField.dependence_value.includes(',')) {
            const allowedValues = dependentField.dependence_value.split(',');
            let dependencyValid = false;
            allowedValues.forEach((value) => {
              dependencyValid = dependencyValid || value === dependencyFieldAnswer.answer;
            });
            return dependencyValid;
          }
          return dependencyFieldAnswer.answer === dependentField.dependence_value;
        }
        // operator "!="
        if (dependencyFieldAnswer && dependentField.dependence_operator === '!=') {
          return dependencyFieldAnswer.answer !== dependentField.dependence_value;
        }
        // operator "in": todo
        // operator "not in": todo
        // If no operator exists, validation is passing
        return true;
      }

      return true;
    },

    requestContact() {
      if (this.contextVisitor.id) {
        this.loading = true;
        addExhibitorContactRequest(this.exhibitorId, this.event, this.contextVisitor.id).then(
          (response) => {
            this.loading = false;
            console.log(response);
            store.commit('addPopup', {
              message: this.translations.visitors.exhibitors.contact_success,
              type: 'success',
              autohide: true,
            });
          },
        );
      } else {
        store.commit('openLoginPrompt');
      }
    },

    getYoutubeEmbededLink(answer: string) {
      let embeddedLink = answer;
      try {
        if (answer) {
          if (answer.includes('embed')) {
            embeddedLink = answer;
          } else {
            const youtubeId = getYoutubeId(answer);

            if (youtubeId) {
              embeddedLink = `https://www.youtube.com/embed/${youtubeId}`;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
      return embeddedLink;
    },

    openExhibitorChat() {
      if (this.contextVisitor.id) {
        getRocketUser(`e${this.exhibitorId}`)
          .then((response) => {
            const { user } = response.data;
            createRocketInstantMessage(user.username).then((response) => {
              const roomId = response.data.room.rid;
              store.commit('setChatRoom', { rid: roomId, type: 'im', roomName: `${user.name}` });
              store.commit('setChatOpen');
              this.$gtag.event(`exhibitor_chat_${this.exhibitor.id}`, {
                event_category: `event_${this.event}`,
                event_label: `visitor_${this.contextVisitor.id}`,
              });
            });
          })
          .catch((err) => {
            console.log(err);
            store.commit('addPopup', {
              type: 'warning',
              message: this.translations.common.user_chat_failed,
              autohide: true,
            });
          });
      } else {
        store.commit('openLoginPrompt');
      }
    },
  },
  watch: {
    $route(next, prev) {
      if (prev.path.includes('/profile/edit')) {
        this.initExhibitor();
      }
    },
  },
});
