






















































































import { defineComponent, PropType, reactive } from '@vue/composition-api';
import { getFileById } from '@/api/files';
import { IAddOnTypeArrayEntity, IParticipatingAnswer } from '@/models/brand';
import useUtils from '@/composition/utils';
import useContext from '@/composition/context';

// @ is an alias to /src
export default defineComponent({
  props: {
    exhibitorAnswers: {
      type: Array as PropType<IParticipatingAnswer[]>,
      required: true,
    },
    videoUrl: String,
  },
  setup() {
    const { isUrl } = useUtils();
    const { translations } = useContext();

    const fileLoading = reactive([false]);

    const downloadFile = (answer: IParticipatingAnswer) => {
      fileLoading[answer.id] = true;
      const downloadFile = (file: string, name = '') => {
        fileLoading[answer.id] = false;
        const link = document.createElement('a');
        link.href = file;
        if (name !== '') {
          link.setAttribute('download', name);
        }
        link.setAttribute('target', '_blank');
        link.click();
      };
      const file = answer.answer as string;
      if (typeof file === 'string' && isUrl(file)) {
        downloadFile(file);
      }

      getFileById(file).then((response) => {
        const { file, name } = response.data;
        fileLoading[answer.id] = true;
        downloadFile(file, name);
      });
    };

    const answerIsEmpty = (answer: IParticipatingAnswer) => {
      try {
        if (answer.field_type === 'checkbox') {
          return (<IAddOnTypeArrayEntity[]>answer.answer).filter((a) => a.checked).length < 1;
        }
        return (<string>answer.answer).trim() === '';
      } catch (error) {
        // do nothing
      }
      return false;
    };

    return {
      downloadFile,
      answerIsEmpty,
      fileLoading,
      translations,
    };
  },
});
