var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exhibitor-details"},[(_vm.videoUrl && _vm.videoUrl !== '')?_c('div',{staticClass:"video-container"},[_c('iframe',{attrs:{"src":_vm.videoUrl,"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):_vm._e(),_vm._l((_vm.exhibitorAnswers),function(answer){return _c('div',{key:answer.id,staticClass:"answer"},[(answer.answer && !_vm.answerIsEmpty(answer) && !answer.hide_from_visitors)?[_c('p',{staticClass:"exhibitor-field-name"},[_vm._v(" "+_vm._s(answer.title)+" ")]),(
          answer.field_type &&
          answer.validation &&
          ['text', 'textarea', 'select', 'radio', 'multiple_select'].includes(
            answer.field_type
          ) &&
          !['url', 'email'].includes(answer.validation)
        )?_c('p',{staticClass:"field html-area",domProps:{"innerHTML":_vm._s(answer.answer)}}):_vm._e(),(answer.field_type === 'text' && answer.validation === 'url')?_c('a',{staticClass:"field",attrs:{"href":answer.answer,"target":"_blank"}},[_vm._v(_vm._s(answer.answer)+" ")]):_vm._e(),(answer.field_type === 'text' && answer.validation === 'email')?_c('a',{staticClass:"field",attrs:{"href":'mailto:' + answer.answer,"target":"_blank"}},[_vm._v(_vm._s(answer.answer)+" ")]):_vm._e(),(answer.field_type === 'checkbox' && Array.isArray(answer.answer))?_c('p',_vm._l((answer.answer.filter(
            function (option) { return option.checked && option.label; }
          )),function(option,index){return _c('span',{key:option.label,staticClass:"option",staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(option.label + (index < answer.answer.filter(function (answer) { return answer.checked; }).length - 1 ? ', ' : '')))])}),0):_vm._e(),(answer.field_type === 'file')?_c('button',{staticClass:"button is-success is-small",class:{ 'is-loading': _vm.fileLoading[answer.id] },attrs:{"type":"button"},on:{"click":function($event){return _vm.downloadFile(answer)}}},[_vm._v(" "+_vm._s(_vm.translations.common.download)+" ")]):_vm._e()]:_vm._e()],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }