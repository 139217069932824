import axios from 'axios';
import { Resolve, IData } from '.';
import config from './config';
import { IBrandField } from '../models/brand';
import { IAttendingField } from '../models/fields';

const url = process.env.VUE_APP_SERVER_PATH;

export const getParticipatingFields = (eventId: number) => new Promise((resolve: Resolve<IData<IBrandField>>, reject) => {
  axios
    .get(`${url}/v2/participating-fields?event=${eventId}&page_size=100`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getAttendingFields = (eventId: number) => new Promise((resolve: Resolve<IData<IAttendingField>>, reject) => {
  axios
    .get(`${url}/v2/attending-fields?event=${eventId}&page_size=100`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});
